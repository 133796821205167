import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { Table, TableHeaderItem, TableItem, TableModel, ModalService, BaseModal } from 'carbon-components-angular';
import { UserService } from '../user.service';
import { User } from '../user';

@Component({
  selector: "app-user-modal",
  templateUrl: './user-modal.component.html',
  styleUrls: ["./user.component.scss"]
})
export class UserModal extends BaseModal {
  password: string = "";
  passwordRepeat: string = "";

  public evaError = undefined;
  public isLoading: boolean = false;

  constructor(@Inject("options") public options, private userService: UserService) {
    super();
    if(!options.editUser) {
      options.user = {};
    } else {
      // do not work on actual object!
      this.options.user = JSON.parse(JSON.stringify(this.options.user));
    }
  }

  editUserSubmit(edit: boolean, user: User) {
    this.evaError = undefined;

    if(this.password.length > 0) {
      if(this.password !== this.passwordRepeat) {
        this.evaError = {
          type: 'error',
          title: 400,
          message: "Passwords do not match.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        return false;
      }

      user.password = this.password;
    }

    this.isLoading = true;

    if(edit) {
      this.userService.updateUser(this.options.user).subscribe(() => {
        this.isLoading = false;
        this.options.action = true;
        return this.closeModal();
      },
      err => {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: err.errCode,
          message: err.errMsg,
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.isLoading = false;
      });
    } else {
      this.userService.createUser(this.options.user).subscribe(() => {
        this.isLoading = false;
        this.options.action = true;
        return this.closeModal();
      },
      err => {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: err.errCode,
          message: err.errMsg,
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.isLoading = false;
      });
    }
  }
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild('overflowTableMenu', {static: true})
  overflowTableMenu: TemplateRef<any>;

  @ViewChild('tableStatusColumn', {static: true})
  tableStatusColumn: TemplateRef<any>;

  skeletonModel = Table.skeletonModel(10, 6);
  skeleton = true;

  public isLoading: boolean = true;
  public evaError = undefined;

  public model = new TableModel();

  constructor(public modalService: ModalService, private userService: UserService) { 
  }

  ngOnInit() {
    this.getUsers();
    this.model.header = [
      new TableHeaderItem({data: "User"}), 
      new TableHeaderItem({data: "Username"}), 
      new TableHeaderItem({data: "Last login"}), 
      new TableHeaderItem({data: "Created on"}), 
      new TableHeaderItem({data: "Status"}), 
      new TableHeaderItem({data: "Actions"})
    ];
  }

  newUser() {
    let options = {
      editUser: false,
      action: false
    };
    const modal = this.modalService.create({
      component: UserModal, 
      inputs: { options: options }
    });

    modal.onDestroy(() => {
      if(options.action) {
        this.evaError = {
          type: 'success',
          title: 200,
          message: "Action successful.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
      }
      this.getUsers();
    })
  }

  editUser(user: User) {
    let options = {
      editUser: true,
      user: user,
      action: false
    };
    const modal = this.modalService.create({
      component: UserModal, 
      inputs: { options: options }
    });

    modal.onDestroy((action?: boolean) => {
      if(options.action) {
        this.evaError = {
          type: 'success',
          title: 200,
          message: "Action successful.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
      }
      this.getUsers();
    })
  }

  private async getUsers() {
    this.isLoading = true;
    this.skeleton = true;
    if(this.evaError && this.evaError.type !== "success") {
      this.evaError = undefined;
    }
    this.userService.getUsers().subscribe(users => {
      try {
        this.model.data.splice(0, this.model.data.length);
        users.forEach(user => {
          this.model.data.push([
            new TableItem({data: user.firstname + " " + user.surname}), 
            new TableItem({data: user.username}),
            new TableItem({data: user.last_login}),
            new TableItem({data: user.created_on}),
            new TableItem({data: user.active, template: this.tableStatusColumn}),
            new TableItem({data: user, template: this.overflowTableMenu})]);
        });
        this.skeleton = false;
        this.isLoading = false;
      } catch(err) {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: 500,
          message: "Users could not be loaded.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.isLoading = false;
      }
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  public async activateUser(username: string) {
    this.isLoading = true;
    this.evaError = undefined;
    this.userService.activateUser(username).subscribe(() => {
      return this.getUsers();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  public async deactivateUser(username: string) {
    this.isLoading = true;
    this.evaError = undefined;
    this.userService.deactivateUser(username).subscribe(() => {
      return this.getUsers();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

}