export class EvaUser {
    public email: string;
    public github: string;
    public license: string;
    public licenseId: string;
    public project: string;
    
	constructor($email: string, $github: string, $license: string, $licenseId: string, $project: string) {
		this.email = $email;
		this.github = $github;
        this.license = $license;
        this.licenseId = $licenseId
        this.project = $project;
	}  

    /**
     * Getter $email
     * @return {string}
     */
	public get $email(): string {
		return this.email;
	}

    /**
     * Getter $github
     * @return {string}
     */
	public get $github(): string {
		return this.github;
	}

    /**
     * Getter $license
     * @return {string}
     */
	public get $license(): string {
		return this.license;
    }
    
    /**
     * Getter $licenseId
     * @return {string}
     */
	public get $licenseId(): string {
		return this.licenseId;
	}

    /**
     * Setter $email
     * @param {string} value
     */
	public set $email(value: string) {
		this.email = value;
	}

    /**
     * Setter $github
     * @param {string} value
     */
	public set $github(value: string) {
		this.github = value;
	}

    /**
     * Setter $license
     * @param {string} value
     */
	public set $license(value: string) {
		this.license = value;
    }
    
    /**
     * Setter $licenseId
     * @param {string} value
     */
	public set $licenseId(value: string) {
		this.licenseId = value;
    }
    

    /**
     * Getter $project
     * @return {string}
     */
	public get $project(): string {
		return this.project;
	}

    /**
     * Setter $project
     * @param {string} value
     */
	public set $project(value: string) {
		this.project = value;
	}

}
