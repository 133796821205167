import { Component, OnInit } from '@angular/core';
import { TableModel, Table, TableHeaderItem, TableItem } from 'carbon-components-angular';

import { UserLogService } from '../user-log.service';

@Component({
  selector: 'app-admin-logs',
  templateUrl: './admin-logs.component.html',
  styleUrls: ['./admin-logs.component.scss']
})
export class AdminLogsComponent implements OnInit {

  skeletonModel = Table.skeletonModel(10, 6);
  skeleton = true;

  public isLoading: boolean = true;
  public evaError = undefined;

  public model = new TableModel();

  constructor(private userLogService: UserLogService) { 
  }

  ngOnInit() {
    this.getUserLogs(10, 1);
    this.model.header = [
      new TableHeaderItem({data: "Username"}), 
      new TableHeaderItem({data: "Content"}), 
      new TableHeaderItem({data: "Date"})
    ];
  }

  private async getUserLogs(limit: number, page: number) {
    this.isLoading = true;
    this.skeleton = true;
    if(this.evaError && this.evaError.type !== "success") {
      this.evaError = undefined;
    }
    this.userLogService.getUserLogs(limit, page).subscribe(userLogsObject => {
      try {
        this.model.data.splice(0, this.model.data.length);
        userLogsObject.userLogs.forEach(userLog => {
          this.model.data.push([
            new TableItem({data: userLog.log_username}), 
            new TableItem({data: userLog.log_content}),
            new TableItem({data: userLog.log_timestamp})
          ]);
        });
        this.model.totalDataLength = userLogsObject.totalDataLength;
        this.skeleton = false;
        this.isLoading = false;
      } catch(err) {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: 500,
          message: "AdminLogs could not be loaded.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.isLoading = false;
      }
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  selectPage($event) {
    this.model.currentPage = $event;
    this.getUserLogs(this.model.pageLength, this.model.currentPage);
  }

}