import { LicenseObject } from './license-object';

export class License {
    license_id: string;
    license_owner: string;
    license_owner_email: string;
    license_project: string;
    license_type: string;
    license_active: boolean;
    license_state: string;
    registered_urls: number;
    max_urls: LicenseObject;
    clients_in_use: number;
    max_clients: LicenseObject;
    license_expiration: string;
}
