import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EvaUser } from './eva-user';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { License } from './license';
import { LicenseType } from './license-type';
import { UrlObject } from './url-object';
import { LicenseLog } from './license-log';

const licenseUrl = '/api/v1/license/';
const licenseMgmtUrl = '/api/v1/licenses';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  registerLicense(evaUser: EvaUser): Observable<string> {
    return this.http.post<string>(licenseUrl + 'register', evaUser, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  saveLicense(license: License): Observable<any> {
    return this.http.post<any>(licenseMgmtUrl, license).pipe(
      catchError(this.handleError)
    );
  }

  getLicenses(activeLicensesOnly: boolean): Observable<License[]> {
    return this.http.get<License[]>(licenseMgmtUrl + "/active/" + activeLicensesOnly.toString()).pipe(catchError(this.handleError));
  }

  getLicenseTypes(): Observable<LicenseType[]> {
    return this.http.get<LicenseType[]>(licenseMgmtUrl + "/types").pipe(catchError(this.handleError));
  }

  getLicenseById(license_id: string): Observable<License> {
    return this.http.get<License>(licenseMgmtUrl + "/" + license_id).pipe(catchError(this.handleError));
  }

  activateLicense(license_id: string): Observable<any> {
    return this.http.put<any>(licenseMgmtUrl + "/" + license_id + "/activate", {}).pipe(catchError(this.handleError));
  }

  deactivateLicense(license_id: string): Observable<any> {
    return this.http.put<any>(licenseMgmtUrl + "/" + license_id + "/deactivate", {}).pipe(catchError(this.handleError));
  }

  getLicenseLogs(license_id: string): Observable<LicenseLog[]> {
    return this.http.get<LicenseLog[]>(licenseMgmtUrl + "/" + license_id + "/logs", {}).pipe(catchError(this.handleError));
  }

  getURLs(license_id: string): Observable<UrlObject[]> {
    return this.http.get<UrlObject[]>(licenseMgmtUrl + "/" + license_id + "/urls", {}).pipe(catchError(this.handleError));
  }

  addURL(urlObject: UrlObject): Observable<any> {
    return this.http.post<any>(licenseMgmtUrl + "/" + urlObject.license_id + "/urls", urlObject).pipe(
      catchError(this.handleError)
    );
  }

  deleteURL(license_id: string, url_id: string): Observable<any> {
    return this.http.delete<any>(licenseMgmtUrl + "/" + license_id + "/urls/" + url_id, {}).pipe(catchError(this.handleError));
  }

  changeLicenseLogResolved(license_id: string, log_id: string, resolved: boolean): Observable<any> {
    return this.http.put<any>(licenseMgmtUrl + "/" + license_id + "/logs/" + log_id + "/" + resolved, {}).pipe(catchError(this.handleError));
  }

  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      error.status = 400;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

      if(error.status === 400 && error.error.errCode && error.error.errCode === 404 && error.error.errMsg && error.error.errMsg === "github_collaborator_not_found") {
        error.status = 404;
        errorMessage = error.error.errMsg;
      }
    }
    return throwError({errCode: error.status, errMsg: errorMessage});
  }
}
