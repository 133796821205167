import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserLogsObject } from './user-logs-object';

const userLogUrl = '/api/v1/logs';

@Injectable({
  providedIn: 'root'
})
export class UserLogService {

  constructor(private http: HttpClient) {
  }

  getUserLogs(limit: number, page: number): Observable<UserLogsObject> {
    return this.http.get<UserLogsObject>(userLogUrl + '?limit=' + limit + '&page=' + page).pipe(catchError(this.handleError));
  }

  private handleError(error) {
    // TODO
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      error.status = 400;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError({errCode: error.status, errMsg: errorMessage});
  }
}
