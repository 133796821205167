import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from './login-credentials';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from './user';

const loginUrl = '/api/v1/auth/';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  login(loginCredentials: LoginCredentials): Observable<User> {
    return this.http.post<User>(loginUrl + 'login', loginCredentials).pipe(
      catchError(this.handleError),
      map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  private handleError(error) {
    // TODO
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      error.status = 400;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if(error.status === 403 && error.error === "user_not_active") {
        error.status = 403;
        errorMessage = "User deactivated.";
      } else if(error.status === 403) {
        error.status = 403;
        errorMessage = "Wrong credentials.";
      }
    }
    return throwError({errCode: error.status, errMsg: errorMessage});
  }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  isLoggedIn() {
    if(localStorage.getItem('currentUser') && this.currentUserValue) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
  }
}
