import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { Table, TableHeaderItem, TableItem, TableModel, HeaderItem } from 'carbon-components-angular';
import { LicenseService } from '../license.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-licensing',
  templateUrl: './licensing.component.html',
  styleUrls: ['./licensing.component.scss']
})
export class LicensingComponent implements OnInit {

  @ViewChild('tableActionsMenu', {static: true})
  tableActionsMenu: TemplateRef<any>;

  @ViewChild('tableStatusColumn', {static: true})
  tableStatusColumn: TemplateRef<any>;

  @ViewChild('tableActiveColumn', {static: true})
  tableActiveColumn: TemplateRef<any>;

  skeletonModel = Table.skeletonModel(10, 6);
  skeleton = true;

  public isLoading: boolean = true;
  public evaError = undefined;
  public activeLicensesOnly = true;

  private sortBy: number;
  private sortAscending: boolean;

  public model = new TableModel();

  constructor(private router: Router, private licenseService: LicenseService) {
  }

  ngOnInit() {
    this.getLicenses();

    this.model.header = [
      new TableHeaderItem({data: "Project"}),
      new TableHeaderItem({data: "Owner Mail (Github)"}),
      new TableHeaderItem({data: "Clients"}),
      new TableHeaderItem({data: "URLs"}),
      new TableHeaderItem({data: "License"}),
      new TableHeaderItem({data: "Status"}),
      new TableHeaderItem({data: "Active"}),
      new TableHeaderItem({data: "Actions"})
    ];
  }

  private async getLicenses() {
    this.isLoading = true;
    this.skeleton = true;
    if(this.evaError && this.evaError.type !== "success") {
      this.evaError = undefined;
    }
    this.licenseService.getLicenses(this.activeLicensesOnly).subscribe(licenses => {
      try {
        this.model.data.splice(0, this.model.data.length);
        licenses.forEach(license => {
          this.model.data.push([
            new TableItem({data: license.license_project}),
            new TableItem({data: license.license_owner_email + " (" + license.license_owner + ")"}),
            new TableItem({data: license.clients_in_use + " / " + license.max_clients.value}),
            new TableItem({data: license.registered_urls + " / " + license.max_urls.value}),
            new TableItem({data: license.license_type}),
            new TableItem({data: license.license_state, template: this.tableStatusColumn}),
            new TableItem({data: license.license_active, template: this.tableActiveColumn}),
            new TableItem({data: license, template: this.tableActionsMenu})]);
        });
        this.skeleton = false;
        this.isLoading = false;
      } catch(err) {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: 500,
          message: "Licenses could not be loaded.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.isLoading = false;
      }
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  public async activateLicense(license_id: string) {
    this.isLoading = true;
    this.evaError = undefined;
    this.licenseService.activateLicense(license_id).subscribe(() => {
      return this.getLicenses();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  public async deactivateLicense(license_id: string) {
    this.isLoading = true;
    this.evaError = undefined;
    this.licenseService.deactivateLicense(license_id).subscribe(() => {
      return this.getLicenses();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  public addLicense() {
    this.router.navigate(['/admin/licensing/0']);
  }

  public editLicense(license_id: string) {
    this.router.navigate(['/admin/licensing/' + license_id]);
  }

  public sort($event) {
    this.model.header.forEach(tableHeaderItem => {
      tableHeaderItem.sorted = false;
    });
    this.model.header[$event].sorted = true;
    this.model.header[$event].ascending = !this.model.header[$event].ascending;
    this.sortBy = $event;
    this.sortAscending = this.model.header[$event].ascending;

    this.model.data.sort((a, b) => {
      if(this.sortBy != 5) {
        if (a[this.sortBy].data < b[this.sortBy].data) {
          if(this.sortAscending) {
            return 1;
          } else {
            return -1;
          }
        }
        if (a[this.sortBy].data > b[this.sortBy].data) {
          if(this.sortAscending) {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      } else {
        let aNo: number = 0;
        if(a[this.sortBy].data == "warning") {
          aNo = 1;
        } else if(a[this.sortBy].data == "error") {
          aNo = 2;
        }

        let bNo: number = 0;
        if(b[this.sortBy].data == "warning") {
          bNo = 1;
        } else if(b[this.sortBy].data == "error") {
          bNo = 2;
        }

        if (aNo < bNo) {
          if(this.sortAscending) {
            return 1;
          } else {
            return -1;
          }
        }
        if (aNo > bNo) {
          if(this.sortAscending) {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      }
    });
  }

  public toggleLicensesActiveOnly() {
    this.activeLicensesOnly = !this.activeLicensesOnly;
    this.getLicenses();
  }
}
