import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { License } from '../license';
import { LicenseService } from '../license.service';
import { LicenseType } from '../license-type';
import { LicenseObject } from '../license-object';
import { TableModel, Table, TableHeaderItem, TableItem } from 'carbon-components-angular';
import { LicenseLog } from '../license-log';
import { UrlObject } from '../url-object';

@Component({
  selector: 'app-licensing-detail',
  templateUrl: './licensing-detail.component.html',
  styleUrls: ['./licensing-detail.component.scss']
})
export class LicensingDetailComponent implements OnInit {
  public isLoading: boolean = false;
  public evaError = undefined;

  public newLicense: boolean = true;
  public license_id: string = "0";
  public license: License = new License();
  public licenseTypes: LicenseType[] = new Array<LicenseType>();

  skeletonModel = Table.skeletonModel(1, 2);
  urlSkeleton = true;

  logSkeletonModel = Table.skeletonModel(10, 3);
  logSkeleton = true;

  public urlModel = new TableModel();
  public logModel = new TableModel();
  public newUrl: string;

  @ViewChild('urlTableActionsMenu', {static: true})
  urlTableActionsMenu: TemplateRef<any>;

  @ViewChild('tableStatusColumn', {static: true})
  tableStatusColumn: TemplateRef<any>;

  @ViewChild('logTableResolveColumn', {static: true})
  logTableResolveColumn: TemplateRef<any>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private licenseService: LicenseService) {
    this.license.max_clients = new LicenseObject();
    this.license.max_urls = new LicenseObject();
    this.license.license_type = "default";

    this.activatedRoute.params.subscribe(params => {
      const license_id: string = params["licenseId"];
      this.license_id = license_id;
      if(license_id !== "0") {
        this.newLicense = false;
      }
    });

    this.urlModel.header = [
      new TableHeaderItem({data: "URL"}), 
      new TableHeaderItem({data: "Delete"})
    ];

    this.logModel.header = [
      new TableHeaderItem({data: "Content"}),
      new TableHeaderItem({data: "Created On"}),
      new TableHeaderItem({data: "Last Update"}),
      new TableHeaderItem({data: "Status"}),
      new TableHeaderItem({data: "Resolved"})
    ];
  }

  ngOnInit() {
    this.licenseService.getLicenseTypes().subscribe(license_types => {
      this.licenseTypes = license_types;
      if(!this.newLicense) {
        this.isLoading = true;
        this.licenseService.getLicenseById(this.license_id).subscribe(license => {
          this.license = license;
          this.isLoading = false;
          this.loadURLs();
          this.loadLicenseLogs();
        },
        err => {
          console.error(err);
          this.evaError = {
            type: 'error',
            title: 500,
            message: "License could not be loaded.",
            showClose: 'showClose',
            lowContrast: 'lowContrast'
          };
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: 500,
        message: "License types could not be loaded.",
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  loadURLs() {
    this.urlSkeleton = true;
    this.licenseService.getURLs(this.license.license_id).subscribe(urls => {
      try {
        this.urlModel.data.splice(0, this.urlModel.data.length);
        urls.forEach(url => {
          this.urlModel.data.push([
            new TableItem({data: url.url}), 
            new TableItem({data: url, template: this.urlTableActionsMenu})]);
        });
        this.urlSkeleton = false;
      } catch(err) {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: 500,
          message: "URLs for license could not be loaded.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
      }
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  loadLicenseLogs() {
    this.logSkeleton = true;
    this.licenseService.getLicenseLogs(this.license.license_id).subscribe(logs => {
      try {
        this.logModel.data.splice(0, this.logModel.data.length);
        logs.forEach(log => {
          this.logModel.data.push([
            new TableItem({data: log.log_content}),
            new TableItem({data: log.log_created_on}),
            new TableItem({data: log.log_last_update + " (" + log.log_last_update_username + ")"}),
            new TableItem({data: log.log_type, template: this.tableStatusColumn}),
            new TableItem({data: log, template: this.logTableResolveColumn})]);
        });
        this.logSkeleton = false;
      } catch(err) {
        console.error(err);
        this.evaError = {
          type: 'error',
          title: 500,
          message: "Logs for license could not be loaded.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
      }
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  backLicenseManagement() {
    this.router.navigate(['/admin/licensing']);
  }

  licenseTypeSelected($event) {
    this.licenseTypes.forEach(licenseType => {
      if($event === licenseType.type) {
        this.license.max_clients.customized = false;
        this.license.max_clients.value = licenseType.max_clients.toString();
        this.license.max_urls.customized = false;
        this.license.max_urls.value = licenseType.max_urls.toString();
      }
    });
  }

  maxClientsChanged($event) {
    const selected_license_type = this.license.license_type;
    this.license.max_clients.customized = true;
    this.licenseTypes.forEach(licenseType => {
      if(selected_license_type === licenseType.type) {
        if(licenseType.max_clients === parseInt($event)) {
          this.license.max_clients.customized = false;
        }
      }
    });
  }

  maxURLsChanged($event) {
    const selected_license_type = this.license.license_type;
    this.license.max_urls.customized = true;
    this.licenseTypes.forEach(licenseType => {
      if(selected_license_type === licenseType.type) {
        if(licenseType.max_urls === parseInt($event)) {
          this.license.max_urls.customized = false;
        }
      }
    });
  }

  saveLicense() {
    this.isLoading = true;
    this.licenseService.saveLicense(this.license).subscribe(() => {
      this.router.navigate(['/admin/licensing']);
      this.isLoading = false;
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

  changeResolvedStatus(licenseLog: LicenseLog) {
    this.isLoading = true;
    this.licenseService.changeLicenseLogResolved(licenseLog.license_id, licenseLog.log_id, licenseLog.log_resolved).subscribe(() => {
      this.isLoading = false;
      this.loadLicenseLogs();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
      this.loadLicenseLogs();
    });
  }

  addURL() {
    this.isLoading = true;
    let urlObject: UrlObject = new UrlObject();
    urlObject.license_id = this.license.license_id;
    urlObject.url = this.newUrl;
    this.licenseService.addURL(urlObject).subscribe(() => {
      this.isLoading = false;
      this.newUrl = "";
      this.loadURLs();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
      this.loadURLs();
    });
  }

  deleteURL(url: UrlObject) {
    this.isLoading = true;
    this.licenseService.deleteURL(url.license_id, url.url_id).subscribe(() => {
      this.isLoading = false;
      this.newUrl = "";
      this.loadURLs();
    },
    err => {
      console.error(err);
      this.evaError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
      this.loadURLs();
    });
  }

}
