import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from './user';

const usersUrl = '/api/v1/users';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(usersUrl).pipe(catchError(this.handleError));
  }

  createUser(user: User): Observable<any> {
    return this.http.post<any>(usersUrl, user).pipe(catchError(this.handleError));
  }

  updateUser(user: User): Observable<any> {
    return this.http.put<any>(usersUrl + "/" + user.username, user).pipe(catchError(this.handleError));
  }

  activateUser(username: string): Observable<any> {
    return this.http.put<any>(usersUrl + "/" + username + "/activate", {}).pipe(catchError(this.handleError));
  }

  deactivateUser(username: string): Observable<any> {
    return this.http.put<any>(usersUrl + "/" + username + "/deactivate", {}).pipe(catchError(this.handleError));
  }

  private handleError(error) {
    // TODO
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      error.status = 400;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError({errCode: error.status, errMsg: errorMessage});
  }
}
