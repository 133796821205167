import { Component, OnInit } from '@angular/core';

import { EvaUser } from '../eva-user';

import jwt_decode from 'jwt-decode';
import { LicenseService } from '../license.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registrationError = undefined;
  public isLoading: boolean = false;
  public registrationEnabled: boolean = true;

  public steps = [
    {
      text: "Register",
      state: ["current"]
    },
    {
      text: "Download License",
      state: ["incomplete"],
    },
    {
      text: "Access EVA Repository",
      state: ["incomplete"],
    }
  ];

  public evaUser: EvaUser = new EvaUser(undefined, undefined, undefined, undefined, undefined);

  private licenseTest: string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbnNlX2lkIjoiMTIzNDU2Nzg5MCIsImxpY2Vuc2Vfb3duZXIiOiJKb2huIERvZSIsImxpY2Vuc2Vfb3duZXJfZW1haWwiOiJqZG9lQGRlLmlibS5jb20ifQ.Yqv3rVK4pqiVcOMJM-vOV7IEKKRaDrFzuv_pEbrXfEA";

  constructor(private licenseService: LicenseService) { }

  ngOnInit() {
  }

  public registerLicense() {
    this.isLoading = true;
    this.registrationError = undefined;
    this.licenseService.registerLicense(this.evaUser).subscribe(license => {
      try {
        this.evaUser.$license = license;
        const decodedLicense = <any> jwt_decode(this.evaUser.$license);
        if(!decodedLicense.license_id) {
          throw new Error("license_id missing");
        }
        this.evaUser.$licenseId = decodedLicense.license_id;
        this.steps[0].state = ["complete"];
        this.steps[1].state = ["current"];
        this.isLoading = false;
      } catch(err) {
        console.error(err);
        this.registrationError = {
          type: 'error',
          title: 500,
          message: "Der Server hat keine Lizenz ID in der Lizenz-Datei geliefert. Bitte versuchen Sie es später erneut.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.steps[0].state = ["current", "error"];
        this.isLoading = false;
      }
    },
    err => {
      console.error(err);
      this.registrationError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      if(err.errCode === 404 && err.errMsg === "github_collaborator_not_found") {
        this.registrationError.title = "Github Benutzer \"" + this.evaUser.$github + "\" nicht gefunden";
        this.registrationError.message = "Der angegebene Benutzer wurde auf github.ibm.com nicht gefunden. Bitte prüfen Sie Ihren Github Benutzernamen erneut auf <a href='https://github.ibm.com' target='_blank'>github.ibm.com</a>.";
      }
      this.steps[0].state = ["current", "error"];
      this.isLoading = false;
    });
  }

  public downloadLicenseFile() {
    this.isLoading = true;
    const blob = new Blob([this.evaUser.$license], { type: 'application/json' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(blob);
    element.download = "license.jwt";
    document.body.appendChild(element);
    element.click();
    this.steps[1].state = ["complete"];
    this.steps[2].state = ["current"];
    this.isLoading = false;
  }

}
