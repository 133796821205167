import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// carbon-components-angular default imports
import { ButtonModule, InputModule, UIShellModule, TableModule, NotificationModule, PaginationModule, DialogModule, PlaceholderModule, ModalModule, ProgressIndicatorModule, LoadingModule, SelectModule, DatePickerModule, GridModule, NumberModule } from 'carbon-components-angular';
import { NotificationModule as NotificationModuleIcon } from '@carbon/icons-angular';
import { UserAvatarModule } from '@carbon/icons-angular';
import { ErrorFilledModule } from '@carbon/icons-angular';
import { WarningFilledModule } from '@carbon/icons-angular';
import { CheckmarkFilledModule } from '@carbon/icons-angular';
import { TrashCanModule } from '@carbon/icons-angular';
import { AddModule } from '@carbon/icons-angular';
import { InformationModule } from '@carbon/icons-angular';
import { IbmCloudModule } from '@carbon/icons-angular';
import { HeaderComponent } from './header/header.component';
import { UserComponent, UserModal } from './user/user.component';
import { LicensingComponent } from './licensing/licensing.component';
import { LicensingDetailComponent } from './licensing-detail/licensing-detail.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { JwtInterceptor } from './jwt.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { AdminLogsComponent } from './admin-logs/admin-logs.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserComponent,
    UserModal,
    LicensingComponent,
    LicensingDetailComponent,
    AdminComponent,
    LoginComponent,
    RegisterComponent,
    AdminLogsComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PaginationModule,
    DialogModule,
    FormsModule,
    InputModule,
    UIShellModule,
    NotificationModuleIcon,
    UserAvatarModule,
    ErrorFilledModule,
    WarningFilledModule,
    TableModule,
    CheckmarkFilledModule,
    TrashCanModule,
    AddModule,
    IbmCloudModule,
    InformationModule,
    ModalModule,
    PlaceholderModule,
    ButtonModule,
    ProgressIndicatorModule,
    NotificationModule,
    LoadingModule,
    SelectModule,
    NumberModule,
    DatePickerModule,
    GridModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents: [
    UserModal
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
