import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { LoginCredentials } from '../login-credentials';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginCredentials: LoginCredentials = new LoginCredentials(undefined, undefined);

  public loginError = undefined;
  public isLoading: boolean = false;
  private returnUrl: string;

  constructor(private loginService: LoginService, private route: ActivatedRoute, private router: Router) {
    // redirect to home if already logged in
    if (this.loginService.currentUserValue) { 
      this.router.navigate(['/admin']);
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
  }

  public login() {
    this.isLoading = true;
    this.loginError = undefined;
    this.loginService.login(this.loginCredentials).pipe(first()).subscribe(license => {
      try {
        this.router.navigate([this.returnUrl]);
      } catch(err) {
        // TODO
        console.error(err);
        this.loginError = {
          type: 'error',
          title: 400,
          message: "Unbekannter Fehler beim Einloggen.",
          showClose: 'showClose',
          lowContrast: 'lowContrast'
        };
        this.isLoading = false;
      }
    },
    err => {
      console.error(err);
      this.loginError = {
        type: 'error',
        title: err.errCode,
        message: err.errMsg,
        showClose: 'showClose',
        lowContrast: 'lowContrast'
      };
      this.isLoading = false;
    });
  }

}
