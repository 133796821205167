import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { LicensingComponent } from './licensing/licensing.component';
import { LicensingDetailComponent } from './licensing-detail/licensing-detail.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './auth.guard';
import { AdminLogsComponent } from './admin-logs/admin-logs.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
	{
		path: 'admin', component: AdminComponent, canActivate: [AuthGuard], children: [
			{ path: 'user', component: UserComponent, canActivate: [AuthGuard] },
			{ path: 'licensing', component: LicensingComponent, canActivate: [AuthGuard] },
			{ path: 'licensing/:licenseId', component: LicensingDetailComponent, canActivate: [AuthGuard] },
			{ path: 'logs', component: AdminLogsComponent, canActivate: [AuthGuard] },
			{ path: '', redirectTo: 'licensing', pathMatch: 'full' },
			{ path: '**', redirectTo: 'licensing', pathMatch: 'full' }
		]
	},
	{ path: 'register', component: RegisterComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'contact', component: ContactComponent },
    { path: '', redirectTo: 'register', pathMatch: 'full' },
	{ path: '**', redirectTo: 'register', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
